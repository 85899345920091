<template>
  <BModal
    id="modal-add-quota"
    ref="modal-add-quota"
    centered
    size="md"
    :hide-header="true"
    :hide-footer="true"
    no-close-on-backdrop
  >
    <div class="py-2">
      <div class="mb-2">
        <p class="mb-2 font-semibold text-black text-[24px] text-center">
          Add Quota Card
        </p>
        <p class="text-center">
          Kamu bisa menambahkan total kartu partner
        </p>
      </div>
      <div class="flex flex-column gap-[20px]">
        <div>
          <BRow
            v-for="item in computedDataList"
            :key="item.id"
            class="mb-1"
          >
            <BCol cols="5">
              <span class="text-[14px] text-black">{{ item.label }}</span>
            </BCol>
            <BCol
              cols="7"
              class="flex item-center gap-[5px] pl-0"
            >
              <span>:</span>
              <span class="flex gap-[5px]">
                {{ item.value }}
                <span v-if="item.label === 'Nama Partner' && computedDataLevel && computedDataLevel.level !== 'Regular'">
                  <span v-b-popover.hover.top="computedDataLevel.message">
                    <img
                      width="24px"
                      :src="computedDataLevel.level_img_url"
                      :alt="computedDataLevel.message"
                    >
                  </span>
                </span>
              </span>
            </BCol>
          </BRow>
        </div>
        <div>
          <div class="mb-[10px]">
            <span class="text-[14px] text-black">Masukkan Notes </span>
            <span class="text-[14px]">(Opsional)</span>
          </div>
          <b-form-textarea
            id="textarea-rows"
            v-model="noteInput"
            placeholder="Masukan Notes"
            rows="4"
            :maxlength="200"
          />
          <span class="text-xs flex justify-content-end">{{ noteInput.length }}/200</span>
        </div>
        <div>
          <div class="mb-[10px]">
            <span class="text-[14px] text-black">Ubah Total Kuota Kartu<span class="text-danger">*</span></span>
          </div>
          <b-form-input
            id="total_quota"
            v-model="totalQuota"
            type="number"
            inputmode="numeric"
            placeholder="Ubah Total Kuota Kartu"
            required
            @keypress="preventInvalidChars"
          />
        </div>
        <div class="flex gap-[10px] mt-2">
          <BButton
            variant="outline-primary"
            class="w-50"
            @click="handleCancelButton"
          >
            Batal
          </BButton>
          <BButton
            :variant="totalQuotaDisabled ? 'secondary' : 'primary'"
            class="w-50"
            :disabled="totalQuotaDisabled"
            @click="sendDataAdd"
          >
            Simpan
          </BButton>
        </div>
      </div>
    </div>
  </BModal>
</template>

<script>
import { IDR } from '@/libs/currency'
import { DAY_MONTH_YEAR } from '@/libs/filterDate'
import { newAxiosIns } from '@/libs/axios'

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    dataQuota: {
      type: Object,
      default: () => ({}),
    },
    dataLevel: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      noteInput: '',
      totalQuota: '',
    }
  },
  computed: {
    computedDataList() {
      return [
        {
          id: 1,
          label: 'Nama Partner',
          value: this.data.name || '-',
        },
        {
          id: 2,
          label: 'Email',
          value: this.data.email || '-',
        },
        {
          id: 3,
          label: 'Spending Bulan Ini',
          value: this.data.total_spending ? IDR(this.data.total_spending) : IDR(0),
        },
        {
          id: 4,
          label: 'Spending Bulan Sebelumnya',
          value: this.dataQuota.prev_monthly_spending ? IDR(this.dataQuota.prev_monthly_spending) : IDR(0),
        },
        {
          id: 5,
          label: 'Tanggal Request Terakhir',
          value: this.dataQuota.last_request_date ? DAY_MONTH_YEAR(this.dataQuota.last_request_date) : '-',
        },
        {
          id: 6,
          label: 'Total Kartu',
          value: this.data.used_card_count && this.data.quota_card_count
            ? `${this.data.used_card_count}/${this.data.quota_card_count}`
            : '-',
        },
        {
          id: 7,
          label: 'Rata-rata Insufficient Balance (2 last months)',
          value: `${Math.floor(this.dataQuota.insufficient_balance_avg) || 0}%`,
        },
        {
          id: 8,
          label: 'Notes Terakhir',
          value: this.dataQuota.last_request_notes || '-',
        },
      ]
    },
    computedDataLevel() {
      return this.dataLevel.length > 0 ? this.dataLevel[0] : null
    },
    totalQuotaDisabled() {
      return this.totalQuota === '' || this.totalQuota === '0' || this.totalQuota.startsWith('0') || this.totalQuota <= this.data.quota_card_count
    },
  },
  methods: {
    sendDataAdd() {
      const idUser = this.data.user_id
      const params = {
        notes: this.noteInput,
        new_total_card: parseFloat(this.totalQuota),
      }
      this.loading = true
      const url = `komcards/api/v1/user/${idUser}/quota`
      newAxiosIns
        .post(url, { ...params })
        .then(res => {
          this.loading = false
          this.noteInput = ''
          this.totalQuota = ''
          this.$bvModal.hide('modal-add-quota')
          this.$toast_success({ message: 'Berhasil ubah total kuota kartu' })
        })
        .catch(() => {
          this.loading = false
          this.$toast_error({ message: 'Gagal ubah total kuota kartu. Periksa koneksi atau coba lagi nanti.' })
        })
    },
    preventInvalidChars(event) {
      if (event.key === 'e' || event.key === 'E' || event.key === '+' || event.key === '-') {
        event.preventDefault()
      }
    },
    handleCancelButton() {
      this.noteInput = ''
      this.totalQuota = ''
      this.$bvModal.hide('modal-add-quota')
    },
  },
}
</script>

<style>

</style>
